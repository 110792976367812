import React, { useState } from "react";
import Layout from "../../components/Layout";
import OtpInput from "../../components/OptInput";
import { Input } from "../../components/form";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import axios from "axios";
import { signIn } from "next-auth/react";
import eventBus from "../../eventBus";
import { signOut, useSession } from "next-auth/react";
import { PhoneIcon } from "@heroicons/react/24/outline";

export default function Login() {
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const router = useRouter();

  const onSubmit = (data: any) => {
    if (step == 0) setPhone(data.phone);
    if (step >= 2) {
      return axios
        .post(
          `${process.env.NEXT_PUBLIC_BASE_API}auth/otpless-login`,
          {
            phone: phone,
          },
          { withCredentials: true }
        )
        .then((data) => {
          if (data?.data?.data?.token) {
            signIn("fireAuth", {
              ...data.data.data,
              redirect: false,
            });
            localStorage.setItem("token", data.data.token);

            eventBus.$emit("showToast", {
              message: `Welcome ${data.data.data.name}!`,
              type: "success",
            });
            router.replace("/");
          }
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else setStep((step: number) => step + 1);
  };
  return (
    <Layout childClassName="pt-10 px-3 text-white">
      {step == 0 && (
        <div className="flex flex-col justify-center items-start gap-10 text-white px-5">
          <p className="text-[38px] font-lato font-semibold w-60">
            Login to Your Account
          </p>
          <form
            className="relative flex flex-col items-center gap-10 justify-center w-full text-black"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              type="text"
              placeholder="Enter your phone number"
              name="phone"
              className="!pl-12 !h-[60px]"
              outerclassName="!w-full"
              register={register}
              errors={errors}
            ></Input>
            <PhoneIcon className="absolute w-8 top-3.5 left-2 text-gray-500" />
            <button
              type="submit"
              className="text-[20px] font-lato h-16 text-black w-72 rounded-full bg-[#FFD700]"
            >
              Login
            </button>
            <button
              onClick={() => {
                signOut();
              }}
              className="text-[20px] font-lato h-16 text-black w-72 rounded-full bg-[#FFD700]"
            >
              Login
            </button>
          </form>
        </div>
      )}
      {step == 1 && (
        <div className="flex flex-col justify-center items-start gap-7">
          <p className="text-[38px] font-lato font-semibold w-72">
            Verify your Phone Number{" "}
          </p>{" "}
          <OtpInput phone={phone} onOtpSubmit={onSubmit} />
        </div>
      )}
      {step == 2 && (
        <div className="flex flex-col justify-center items-start gap-7">
          <p className="text-[38px] font-lato font-semibold w-72">
            Fill in your Details{" "}
          </p>{" "}
          <form
            className="flex flex-col items-center gap-10 justify-center w-full text-black"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-white">Name</p>
              <Input
                type="text"
                placeholder="Enter your name"
                name="name"
                className="!pl-10 !h-12"
                outerclassName="!w-full"
                register={register}
                errors={errors}
              ></Input>
            </div>
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-white">Email</p>
              <Input
                type="text"
                placeholder="Enter your email address"
                name="email"
                className="!pl-10 !h-12"
                outerclassName="!w-full"
                register={register}
                errors={errors}
              ></Input>
            </div>
            <button
              type="submit"
              className="text-[20px] font-lato h-16 text-black w-72 rounded-full bg-[#FFD700]"
            >
              Continue
            </button>
          </form>
        </div>
      )}
    </Layout>
  );
}
