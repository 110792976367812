export default function Textarea({
  type,
  placeholder,
  name,
  register,
  errors,
  rules,
  label,
  rows,
  ...rest
}: {
  type: string;
  placeholder: string;
  name: string;
  register: any;
  errors: any;
  rules?: any;
  label?: string;
  rows?: number;
}) {
  return (
    <div>
      {label && (
        <label>
          {label} {rules?.required && <span className="text-red-600">*</span>}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        className={`${errors[name] && "input-invalid"}`}
        rows={rows}
        {...register(name, rules)}
        {...rest}
      />
      {errors[name] && (
        <small className="mt-1.5 text-red-600 block">
          {errors[name].message || "This field is required"}
        </small>
      )}
    </div>
  );
}
